import { create } from "zustand";
import axios from "axios";

const REACT_APP_AWS_API_GATEWAY = process.env.REACT_APP_AWS_API_GATEWAY;

const DEFAULT_USER = {
    id: null,
    username: "",
    bio: "",
    pfp: "/images/Default-PFP.png",
    onboarded: false
};

const store = (set) => ({
    isUserLoading: true,
    user: DEFAULT_USER,
    getUser: async () => {
        const u = (await axios.get(`${REACT_APP_AWS_API_GATEWAY}/api/e/v1/auth/get_user`, { withCredentials: true })).data; 
        //adding withCredentials enabled auth to work *in dev environment*
        set((state) => ({
            isUserLoading: false,
            user: {
                ...state.user,
                id: u._id,
                username: u.username,
                bio: u.bio,
                pfp: u.pfp,
                onboarded: u.onboarded
            }
        }));
    },
});

const useStore = create(store);


const useNotificationsStore = create((set) => ({
    unreadNotificationsCount: 0,
    setUnreadNotificationsCount: (count) => set({ unreadNotificationsCount: count }),
    notifications: [],
    setNotifications: (notifications) => set({ notifications }),
    setMoreNotifications: (notifications) => set((state) => { // this is a way to avoid duplicates.
        //we're merging the new notifications with the old ones, but only if the page number is not already present
        const prev = state.notifications;
        const prevPages = new Set(prev.map(notification => notification.page));
        const newPages = notifications.filter(notification => 
                !prevPages.has(notification.page)
            );
            return {notifications: [...prev, ...newPages]};

    }),
    visibleNotification: 1,
    setVisibleNotification: (page) => set({ visibleNotification: page }),
    setNotificationRead: (notification_id) => set((state) => {
        const prev = structuredClone(state.notifications);
        const newNotifications = prev.map(notification => notification._id === notification_id ? {...notification, is_read: true} : notification);
        return {notifications: newNotifications};
    })
}));


export default useStore;
export { useNotificationsStore };
