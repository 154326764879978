import { useState, useEffect, useRef } from "react";
import { useNotificationsStore } from "../../store/store";
import { Button } from "../ui/shadcn/Button"
// import { Avatar, AvatarImage, AvatarFallback } from "../ui/shadcn/Avatar"
import { Card, CardContent } from "../ui/shadcn/Card"
import { ChevronLeft } from "lucide-react"
import { UserPlus } from "lucide-react"
import { UserRoundCheck } from "lucide-react"
import axios from "axios";
import Header from "../ui/layout/Header";
import Footer from "../ui/layout/Footer";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
const DEFAULT_PFP = "/images/Default-PFP.png";

// NotificationItem is a component that renders a single notification
const NotificationItem = ({notification, navigate, setVisibleNotification, observerRef, page}) => {
  const itemRef = useRef();
  const [isFollowing, setIsFollowing] = useState();
  const [justFollowedBack, setJustFollowedBack] = useState(false);
  const { setNotificationRead } = useNotificationsStore();

  // When a notification is clicked, mark it as read and navigate to the post or profile
  const handleClickNotification = async (e) => {
    await axios.post(`/api/e/v1/social/mark_read`, { notification_id: notification._id });
    setNotificationRead(notification._id);
    if (notification.type !== "follow") {
      window.location.href = notification.url;
    }
    else{
      navigate(`/profile/${notification.sender_id.username}`);
    }
}

// When a user clicks the follow button, toggle follow and send a notification to the user
const handleFollowProfile = async () => {
    try {
        const { data, status } = await axios.post(`/api/e/v1/social/toggle_follow_profile`, {
            profile_id: notification.sender_id._id
        });
        if (status === 200) {
            const message = data?.message;
            if (message === "follow") {
                toast.success("Following profile");
                setIsFollowing(true);
                handleFollowProfileNotification();
            } else if (message === "unfollow") {
                toast.success("Unfollowing profile");
                setIsFollowing(false);
            }
        } else if (status !== 200) {
            const err = data?.err;
            throw new Error({ err, status });
        }
    } catch (err) {
        console.error(err);
        toast.error("Error following profile");
    }
};

const handleFollowProfileNotification = async () => {
  try {
      await axios.post(`/api/e/v1/social/new_follower_sms`, {
          profile_id: notification.sender_id._id
      });
  } catch (err) {
      console.error(err);
  }
}

useEffect(() => {
  const checkIfFollowing = async () => {
    try {
        const { data, status } = await axios.get(`/api/e/v1/social/check_if_following`, {
            params: {
                profile_id: notification.sender_id._id
            }
        });
        if (status === 200) {
            const isFollowing = data?.is_following;
            setIsFollowing(isFollowing);
        } else {
            const err = data?.err;
            throw new Error({ err, status });
        }
    } catch (err) {
        console.error(err);
        toast.error("Error checking if following");
    }
};
  checkIfFollowing();
}, [notification.sender_id._id]);


  
  useEffect(() => {
    //the observer lets us see where we are in the list and load notifications appropriately
    if (!observerRef.current) {
      observerRef.current = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const { target, isIntersecting } = entry;
            const pageData = target.getAttribute('data-page');
            if (isIntersecting){
              setVisibleNotification(pageData);
            }
          });
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 0.5,
        }
      );
    }

    const observer = observerRef.current;
    if (itemRef.current) observer.observe(itemRef.current);
    const toClean = itemRef.current;
    // Cleanup when the component unmounts
    return () => {
      if (itemRef.current) observer.unobserve(itemRef.current); //this might be redundant
      if (toClean) observer.unobserve(toClean);
    };
  }, [setVisibleNotification, observerRef, page,notification._id]);

    return (

      <div key={notification.id} ref={itemRef} data-index={notification.id} data-page={notification.page} className="flex items-center gap-3 p-4 relative cursor-pointer" onClick={(e) => handleClickNotification(e)} style={{ backgroundColor: notification.is_read ? 'white' : 'rgba(182, 46, 39, 0.05)' }}>
      <div className="flex-shrink-0">
                    <img className="w-16 h-16 rounded-full object-cover cursor-pointer" src={notification.sender_id.pfp ? notification.sender_id.pfp : DEFAULT_PFP} alt={notification.user} onClick={(e) => { e.stopPropagation(); navigate(`/profile/${notification.sender_id.username}`); }} />
                </div>
      <div className="flex-1 min-w-0 text-left"> {/* Added text-left for left justification */}
        <p className="text-sm">
          <span className="font-bold cursor-pointer" onClick={(e) => { e.stopPropagation(); navigate(`/profile/${notification.sender_id.username}`); }}>{notification.sender_id.username}</span>
        </p>
        <p className="text-sm">{notification.content}</p>
      </div>
      {(!notification.is_read) && (
        <div className="absolute left-1 top-1/2 -translate-y-1/2">
          <div className="h-2 w-2 rounded-full bg-[#B62E27]" />
        </div>
      )}
    {notification.type === "follow" && !justFollowedBack &&  isFollowing !== undefined && !isFollowing && 
    <Button size="sm" variant="outline" className="px-4 py-2 text-sm md:text-base" onClick={(e) => {
      e.stopPropagation();
      setJustFollowedBack(true);
      handleFollowProfile();
    }}>
      Follow Back
    </Button>
    }
    {
    //variant="outline" onClick={user?.id ? handleFollowProfile : () => navigate("/auth")} className="px-4 py-2 text-sm md:text-base"
    justFollowedBack &&  <Button size="sm" variant="outline" className="px-4 py-2 text-sm md:text-base bg-green-500 hover:bg-green-500" style={{ cursor: 'pointer' }} onClick={(e) => {
      e.stopPropagation();
    }}>
      <UserRoundCheck className="mr-2 h-4 w-4" />
      Followed :)
    </Button>}

    {(notification.type === "like" || notification.type === "comment") && 

        <img className="w-16 h-16 object-contain" src={notification.image} alt="Notification" />
    }
    </div>
    )
}

const Notifications = () => {
    const {notifications, setMoreNotifications, visibleNotification, setVisibleNotification } = useNotificationsStore();
    const navigate = useNavigate();
    const location = useLocation();
    const lastViewedPage = location.state?.from || '/'; // lets us navigate back to the page we were on before
    const observerRef = useRef(null);
    useEffect(() => {
      }, [visibleNotification]);

      // Lazy scrolling: Fetch notifications for the current page and the next 5 pages
    useEffect(() => {
        const fetchNotifications = async () => {
            const visiblePages = notifications.map(notification => notification.page);
            if (visiblePages.includes(Number(visibleNotification) + 3)) { // only fetch if we are close enough to the end of the list
              return;
            }


            const pages = Array.from({ length: 6 }, (_, i) => Number(visibleNotification) + i); // Create an array of pages to fetch
            const notificationsArray = await Promise.all(pages.map(async (page) => {
                const { data } = await axios.get(`/api/e/v1/social/get_notifications?page=${page}`);
                return data.notifications.map(notification => ({ ...notification, page }));
            }));
            
            setMoreNotifications(notificationsArray.flat());

        }
        fetchNotifications();
    }, [visibleNotification,setMoreNotifications,notifications]);

    return (
        <div className="page flex justify-center items-center">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <Header />
          <div className="flex flex-col sm:flex-row justify-center items-center full mt-4">
          <div className="content text-center">
        <Card className="w-full h-full">
        <div className="flex justify-center items-center mt-2 mb-8 relative">

              <ChevronLeft className="h-6 w-6 cursor-pointer absolute left-3 top-3"onClick={() => navigate(lastViewedPage)} />

            <div className="libre-caslon border-b border-b-transparent inline-flex items-center justify-center whitespace-nowrap text-xl px-3 py-0 ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:border-b-primary data-[state=active]:font-weight-600">Notifications</div>
          </div>
        <CardContent className="p-0">

        <div className="divide-y">
       
          {notifications.map((notification) => (
            <NotificationItem key={notification.id} notification={notification} navigate={navigate} setVisibleNotification={setVisibleNotification} page={notification.page} observerRef={observerRef} />
          ))}
        </div>
      </CardContent>
      <div className="flex justify-between mt-4">
      </div>
      </Card>
      </div>
      </div>
      <Footer />
      </div>
        </div>
  
    )
}

export default Notifications;