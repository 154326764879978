import React, { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Auth from "./pages/Auth";
import Book from "./pages/Book";
import Meetings from "./pages/Meetings";
import Feed from "./pages/Feed";
import Profile from "./pages/Profile";
import Search from "./pages/Search";
import Settings from "./pages/Settings";
import Following from "./ui/Following"
import Notifications from "./pages/Notifications"
import useStore, { useNotificationsStore } from "../store/store";
import * as amplitude from '@amplitude/analytics-browser';
import axios from "axios";


const App = () => {
  const { isUserLoading, user, getUser } = useStore((state) => ({
    isUserLoading: state.isUserLoading,
    user: state.user,
    getUser: state.getUser
  }));
  const { setUnreadNotificationsCount, visibleNotification, setNotifications } = useNotificationsStore();
  const navigate = useNavigate();
  useEffect(() => {
    console.log("calling get user in useEffect");
    getUser();
  }, []);

  useEffect(() => {
    if (!isUserLoading && !user.id && !window.location.pathname.match(/^(\/|\/auth|\/profile\/.*|\/book\/.*)$/)) {
      console.log("navigating to /auth");
      navigate("/auth");
    }
    else if (!isUserLoading && user.id && (window.location.pathname === "/" || window.location.pathname === "/auth")) {
      console.log("navigating to /feed");
      navigate("/feed");
    }
  }, [isUserLoading, user, navigate]);



  useEffect(() => {
    amplitude.init("4b73b03c9933db015b979608583cc4b8");
  }, []);

  // Polling to keep notifications updated:
  // every 30 seconds, update count of unread notifications and
  //update first five pages of notifications whenever we're at the top of the notifications screen
  useEffect(() => {
    if (!isUserLoading && user.id) {
      // get initial count of unread notifications
      axios.get("/api/e/v1/social/get_unread_notifications_count")
          .then(res => res.data)
        .then(data => {setUnreadNotificationsCount(data.count); console.log("unreadNotificationsCount", data.count)});
      // every 30 seconds, update count of unread notifications and first five pages of notifications 
      //(if we're at the top of the notifications screen; if we're not we don't update the notifications)
      const interval = setInterval(async () => {
        try {
          const countResponse = await axios.get("/api/e/v1/social/get_unread_notifications_count");
          setUnreadNotificationsCount(countResponse.data.count);
          
          if (visibleNotification <= 2) {
            const pages = Array.from({ length: 5 }, (_, i) => i + 1); // Create an array [1, 2, 3, 4, 5]
            const notificationsArray = await Promise.all(pages.map(async (page) => {
              const { data } = await axios.get(
                `/api/e/v1/social/get_notifications?page=${page}`
              );
              return data.notifications.map(notification => ({ 
                ...notification, 
                page 
              }));
            }));
            setNotifications(notificationsArray.flat());
          }
        } catch (error) {
          console.error('Error fetching notifications:', error);
        }
      }, 30000);

      return () => {
        clearInterval(interval);
      }
    }
  }, [isUserLoading, setUnreadNotificationsCount, user.id, visibleNotification, setNotifications]);

  return (<>

    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover
      theme="light"
      limit={1}
      toastStyle={{ width: "auto", minWidth: "250px" }}
    />

    <Routes>
      <Route path="/auth" element={<Auth />} />
      <Route path="/book/:id" element={<Book />} />
      <Route path="/meetings" element={<Meetings />} />
      <Route path="/feed" element={<Feed />} />
      <Route path="/feed/reviews/:reviewId" element={<Feed />} />
      <Route path="/feed/readings/:readingId" element={<Feed />} />
      <Route path="/feed/toreads/:toReadId" element={<Feed />} />
      <Route path="/profile/:profileUsername" element={<Profile />} />
      <Route path="/profile/:profileUsername/:collectionName" element={<Profile />} />
      <Route path="/profile/:profileUsername/reviews/:reviewId" element={<Profile />} />
      <Route path="/profile/:profileUsername/readings/:readingId" element={<Profile />} />
      <Route path="/profile/:profileUsername/toreads/:toReadId" element={<Profile />} />
      <Route path="/profile/:profileUsername/following" element={<Following />} />
      <Route path="/search" element={<Search />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="*" element={<Navigate to="/auth " replace />} />
    </Routes>
  </>);
};

export default App;