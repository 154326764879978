import React, { useEffect, useState } from "react";
import { Link, useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import { InputBase } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import useStore, { useNotificationsStore } from "../../../store/store";
import { Button } from "../shadcn/Button";
import "../../../styles/index.css"; 

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "white",
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: 0,
        width: "100%",
    },
    display: "flex",
    alignItems: "center",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: "0",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    left: 0,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "100%",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: 0,
        transition: theme.transitions.create("width"),
        [theme.breakpoints.up("sm")]: {
            width: "12ch",
            "&:focus": {
                width: "20ch",
            },
        },
    },
}));

const DEFAULT_SEARCH_QUERY = "";

const Header = () => {
    const [searchQuery, setSearchQuery] = useState(DEFAULT_SEARCH_QUERY);
    const { user, getUser, isUserLoading } = useStore((state) => ({
        user: state.user,
        getUser: state.getUser,
        isUserLoading: state.isUserLoading,
    }));
    const { unreadNotificationsCount } = useNotificationsStore();
    const navigate = useNavigate();

    useEffect(() => {
        getUser();
    }, []);

    const handleSearch = (event) => {
        event.preventDefault();
        navigate(`/search?query=${searchQuery}`);
    };

    const HeaderLink = ({ to, children, startsWith }) => {
        const path = useResolvedPath(to);
        const match = useMatch({ path: path.pathname, end: true });

        const isActive = startsWith
            ? window.location.pathname.startsWith(path.pathname)
            : match;

        const handleClick = (event) => {
            if (!user?.id) {
                event.preventDefault();
                navigate("/auth");
            }
        };

        return (
            <Link to={to} className={isActive ? "header-link-active" : "header-link-inactive"} onClick={handleClick}>
                <small>{children}</small>
            </Link>
        );
    };

    return (
        <div className="flex-col-center-center full gap p2 sm:flex-row-center-center relative">
            <div className="flex-row-center-center w-full sm:w-1/2">
                <Link to="/feed" className="logo-container">
                    <img src="/images/open-book-club.png" alt="obc icon" className="w-3/4 p-3" />
                </Link>
                {!isUserLoading && user.id && (
                    <>
                        <NotificationsNoneIcon 
                            className="absolute right-0 top-4 cursor-pointer" 
                            onClick={() => navigate("/notifications", { state: { from: window.location.pathname } })} 
                        />
                        <span className="absolute right-0 top-2.5 text-xs text-[#B62E27]">{unreadNotificationsCount && unreadNotificationsCount > 0 ? unreadNotificationsCount : ""}</span>
                    </>
                )}
            </div>
        </div>
    );
}

export default Header;